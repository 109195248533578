<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M6.889 4.165c.08.08.174.145.278.189.104.044.216.067.33.067.112.001.225-.02.33-.063.104-.043.2-.106.28-.186l1.032-1.019v6.143c0 .229.09.448.252.61.161.16.38.252.609.252.228 0 .447-.091.609-.253.162-.161.252-.38.252-.609V3.223l.962.95c.162.16.382.25.61.248.229 0 .448-.093.608-.256.16-.162.25-.382.249-.61-.002-.229-.094-.447-.256-.608L11.57 1.501c-.21-.212-.46-.38-.736-.494-.276-.115-.571-.174-.87-.174-.298 0-.594.059-.87.174-.275.114-.525.282-.736.494L6.896 2.946c-.163.161-.255.38-.256.608-.001.229.088.449.249.611z"
      />
      <path
        fill="currentColor"
        d="M18.305 9.454c-.228 0-.447-.091-.609-.253-.161-.161-.252-.38-.252-.609 0-.331-.132-.649-.367-.883-.234-.235-.552-.367-.884-.367h-3.378c-.228 0-.447-.09-.609-.252-.161-.162-.252-.38-.252-.61 0-.228.09-.447.252-.608.162-.162.38-.253.61-.253h3.377c.789.001 1.544.315 2.102.872.557.557.87 1.313.872 2.101 0 .229-.092.448-.253.61-.162.16-.38.251-.609.252zM10 16.391c2.047 0 3.712-1.776 3.712-3.959 0-.837-.213-1.588-.63-2.233-.125-.191-.32-.326-.544-.373-.224-.047-.457-.004-.649.12-.191.125-.325.32-.372.544-.048.224-.004.457.12.648.237.36.355.796.355 1.294 0 1.233-.893 2.236-1.99 2.236-1.096 0-1.988-1.003-1.988-2.236.002-.46.138-.911.39-1.297.062-.095.104-.202.124-.313.02-.112.018-.226-.007-.337-.024-.111-.07-.216-.134-.31-.065-.092-.147-.172-.243-.233-.192-.124-.426-.166-.65-.117-.224.049-.42.184-.543.377-.432.664-.663 1.438-.665 2.23.002 2.183 1.667 3.959 3.714 3.959z"
      />
      <path
        fill="currentColor"
        d="M16.194 19.167H3.806c-.788-.001-1.544-.315-2.1-.872-.558-.558-.872-1.313-.873-2.101V8.592c.001-.788.315-1.544.872-2.1.557-.558 1.313-.872 2.101-.873h3.378c.229 0 .448.091.61.253.161.161.252.38.252.609 0 .228-.09.447-.252.609-.162.161-.381.252-.61.252H3.806c-.331 0-.649.132-.883.367-.235.234-.367.552-.367.883v7.602c0 .331.132.649.367.883.234.235.552.367.883.367h12.388c.331 0 .649-.132.883-.367.235-.234.366-.552.367-.883v-4.08c0-.114.022-.226.065-.33.044-.105.107-.2.187-.28.08-.08.175-.143.28-.187.104-.043.216-.065.33-.065.112 0 .224.022.329.065.104.044.2.107.28.187.08.08.143.175.186.28.043.104.066.216.066.33v4.08c-.001.788-.315 1.544-.872 2.1-.558.558-1.313.872-2.102.873z"
      />
    </svg>
  </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class Topup extends Vue {}
</script>
